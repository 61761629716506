"use client";
import IconWrapper from "@/src/components/icons/icon-wrapper";
import CartIconComponent from "@/src/template/ecommerce/order/cart-icon.component";
import ButtonProfile from "@/src/components/layout-public/btn-profile";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import BlurImage from "@/src/components/blur-image";

interface IProps {
  user: any;
  siteName: string;
  logo?: string;
}

function HeaderActions({ user, siteName, logo }: IProps) {
  // State to track if the header is active
  const [isActive, setIsActive] = useState(false);

  // Effect to handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      // Check if the scroll position is greater than a certain value (e.g., 100px)
      if (window.scrollY > 100) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={cn(
        "action fixed left-0 right-0 top-0 z-10 mx-auto w-full rounded-b-2xl transition-all duration-300 lg:w-[1024px]",
        isActive ? "bg-white shadow-md" : "",
      )}
    >
      <div className="flex justify-between px-4 py-3">
        <IconWrapper showShadow={!isActive}>
          <ButtonProfile user={user} border={false} />
        </IconWrapper>
        <h2
          className={cn(
            "site-title flex items-center justify-center text-center text-3xl",
            isActive ? "opacity-100" : "opacity-0",
          )}
        >
          {siteName}
        </h2>
        <CartIconComponent showShadow={!isActive} />
      </div>
    </div>
  );
}

export default HeaderActions;
