import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/@ant-design/icons/es/components/AntdIcon.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/app-items/social-button/social-share-button.component.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/blur-image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/post/post-section/post-section.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/property/property/property-group.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/public/home/list-post-by-category.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/public/home/nav-category/nav-category-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/template/ecommerce/category-item/category-item.component.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/template/ecommerce/default-laguage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/template/ecommerce/menu-item/menu-item.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/template/ecommerce/order/cart-item-drawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/template/estudy/lesson-item/lesson-item.component.tsx");
