import(/* webpackMode: "eager", webpackExports: ["SiteProvider"] */ "/vercel/path0/portal/app/[domain]/site.provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"Be_Vietnam_Pro\",\"arguments\":[{\"variable\":\"--font-be-vn\",\"subsets\":[\"latin\"],\"weight\":\"400\",\"display\":\"swap\"}],\"variableName\":\"beVN\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/blur-image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/layout-public/btn-profile/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/layout-public/header-property.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/layout-public/language-switcher/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/components/layout-public/main-input-search.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/layout-public/main-nav.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/components/post/post-carousel/post-carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/images/logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/template/ecommerce/bottom-navigation-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/template/ecommerce/header/header-action.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/template/ecommerce/header/header.modules.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/template/ecommerce/order/order-drawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/portal/src/template/estudy/header/header-action.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/template/estudy/header/header.modules.scss");
