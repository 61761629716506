"use client";
import { cx } from "src/utils/all";
import BlurImage from "@/src/components/blur-image";
import { useTranslation } from "@/app/i18n/client";
import { Button } from "antd";
import { ShoppingCart } from "lucide-react";
import { observer } from "mobx-react";
import Link from "next/link";
import { useRouter } from "next/navigation";

const MenuItemComponent = observer(({ data }: any) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { id, name, image, description, aspect, remaining } = data;

  const startLesson = () => {
    router.push(`/lesson/${id}`);
  };

  return (
    <div
      className={cx(
        "relative rounded-2xl bg-white/50 p-4 backdrop-blur-xl transition-all duration-300 hover:shadow-md",
        remaining > 0 ? "cursor-pointer" : "",
      )}
      style={{ boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.07)" }}
    >
      <div className="flex flex-none flex-row gap-4">
        <div className="flex justify-center">
          <div
            className={cx(
              "relative h-20 w-20 rounded-lg transition-all hover:scale-105 md:rounded-full",
              aspect === "landscape" ? "aspect-video" : "aspect-square",
            )}
          >
            <BlurImage
              src={image || "/empty-state.png"}
              alt={name}
              className="rounded-lg md:rounded-full"
              onClick={startLesson}
            />
            {/* Show add to cart when hover */}
            {remaining > 0 && (
              <div
                className="absolute inset-0 flex items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 transition-opacity hover:opacity-100"
                onClick={startLesson}
              >
                <Button
                  icon={<ShoppingCart size={16} />}
                  shape="circle"
                  className="border-none"
                ></Button>
              </div>
            )}
          </div>
        </div>
        <div className="z-1 flex flex-auto flex-row justify-between">
          <div className="flex-auto">
            <Link href={`/lesson/${data.id}`}>
              <h2 className="text-brand-primary flex justify-start text-lg font-semibold tracking-normal dark:text-white">
                <span className="link-underline link-underline-blue uppercase text-slate-500">
                  {name}
                </span>
              </h2>
            </Link>

            <div className="flex justify-start text-base">{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MenuItemComponent;
